import React, { useState, createContext } from "react";

const defaultContext = {
    currentCategory: "",
    updateCurrentCategory: () => { }
};

export const CategoryContext = createContext(defaultContext);

const Provider = (props) => {
    const [currentCategory, setCurrentCategory] = useState("");

    return (
        <CategoryContext.Provider
            value={{
                currentCategory,
                updateCurrentCategory: (newCategory) => setCurrentCategory(newCategory),
            }}
        >
            {props.children}
        </CategoryContext.Provider>
    );
};


export const CategoryContextProvider = (props) => {
    return (
        <Provider>{props.children}</Provider>
    );
};