import React, { useContext, useEffect } from "react";

import { Link, useStaticQuery, graphql } from "gatsby";



// import { BrandFilter } from '../../components/product-filters/brand';
import { ShopNow } from "../../components/buttons/shop-now";
import { CategoryFilter } from "../../components/product-filters/category-filter";
import { MinMaxFilter } from "../../components/product-filters/min-max-filter";
import { OrderByFilter } from "../../components/product-filters/order-by-filter";
// import { OrderBy } from '../../components/product-filters/order-by';
// import { PriceFilter } from '../../components/product-filters/price';
import { RatingFilter } from "../../components/product-filters/rating-filter";
// import { SubCategoryFilter } from '../../components/product-filters/sub-category';
import { CategoryContext, CategoryContextProvider } from "../../context-providers/category-context";
import { LanguageRegionContext } from "../../context-providers/language-region-context";
import { MenuContext } from "../../context-providers/menu-context";
import { ProductFiltersContext, ProductFiltersContextProvider } from "../../context-providers/product-filters-context";
import { SubCategoryContext, SubCategoryContextProvider } from "../../context-providers/sub-category-context";
import {getCategories, getSubCategories } from "../../helpers/get-categories";
import { getPath } from "../../helpers/get-path";
import { getProductFilters } from "../../helpers/get-product-filters";

import "./product-filter.scss";

const getMin = (acceptedValues) => {

    if (acceptedValues && acceptedValues.length > 0) {
        return Math.min(...acceptedValues);
    }
};

const getMax = (acceptedValues) => {
    if (acceptedValues && acceptedValues.length > 0) {
        return Math.max(...acceptedValues);
    }
};

const getValuesForMinMax = (min, max) => {
    let listOfValues = [];
    for (let i = min; i <= max; i = i + 5000) {
        listOfValues.push(i);
    }

    let results = [];

    for (let i = 0; i < listOfValues.length - 1; i++) {
        for (let j = i + 1; j < listOfValues.length; j++) {
            results.push([listOfValues[i],listOfValues[j]]);
        }
    }

    return results;
};

// if (productFilter.filterType === "SubCategoryFilter") {
//     return (
//         <div key={`product-filter-${productFilter.filterName}`} className="g-flex__item g-flex__item--full-width c-product-filter__filter-item">
//             <SubCategoryFilter
//                 productFilter={productFilter}
//                 onChangeHandlerOverride={onChangeHandlerOverride}
//                 productFilters={props.productFilters}
//                 category={props.category}
//                 subCategory={props.subCategory}
//                 languageRegion={props.languageRegion}
//             />
//         </div>
//     )
// }
// if (productFilter.filterType === "BrandFilter") {
//     return (
//         <div key={`product-filter-${productFilter.filterName}`} className="g-flex__item g-flex__item--full-width c-product-filter__filter-item">
//             <BrandFilter
//                 productFilter={productFilter}
//                 onChangeHandlerOverride={onChangeHandlerOverride}
//                 productFilters={props.productFilters}
//                 category={props.category}
//                 subCategory={props.subCategory}
//                 languageRegion={props.languageRegion}
//             />
//         </div>
//     )
// }
// if (productFilter.filterType === "PriceFilter") {
//     return (
//         <div key={`product-filter-${productFilter.filterName}`} className="g-flex__item g-flex__item--full-width c-product-filter__filter-item">
//             <PriceFilter
//                 productFilter={productFilter}
//                 defaultMin={0}
//                 defaultMax={500}
//                 onChangeHandlerOverride={onChangeHandlerOverride}
//                 productFilters={props.productFilters}
//                 category={props.category}
//                 subCategory={props.subCategory}
//                 languageRegion={props.languageRegion}
//             />

//         </div>
//     )
// }
// if (productFilter.filterType === "OrderBy") {
//     return (
//         <div key={`product-filter-${productFilter.filterName}`} className="g-flex__item g-flex__item--full-width c-product-filter__filter-item">
//             <OrderBy
//                 productFilter={productFilter}
//                 onChangeHandlerOverride={onChangeHandlerOverride}
//                 productFilters={props.productFilters}
//                 category={props.category}
//                 subCategory={props.subCategory}
//                 languageRegion={props.languageRegion}
//             />
//         </div>
//     )
// }


const FindProductsLink = ({rating1QueryStringParam, rating2QueryStringParam, rating3QueryStringParam, minMaxQueryStringParam, orderByQueryStringParam}) => {
    const { languageRegion } = useContext(LanguageRegionContext);
    const { currentCategory } = useContext(CategoryContext);
    const { currentSubCategory } = useContext(SubCategoryContext);

    const {
        rating1ProductFilterValue,
        rating2ProductFilterValue,
        rating3ProductFilterValue,
        orderByProductFilterValue,
        minMaxProductFilterValue
    } = useContext(ProductFiltersContext);

    const filters = [
        {queryStringParam: rating1QueryStringParam, value: rating1ProductFilterValue},
        {queryStringParam: rating2QueryStringParam, value: rating2ProductFilterValue},
        {queryStringParam: rating3QueryStringParam, value: rating3ProductFilterValue},
        {queryStringParam: orderByQueryStringParam, value: orderByProductFilterValue},
        {queryStringParam: minMaxQueryStringParam, value: minMaxProductFilterValue}
    ];

    return (
        <Link
            className="g-flex__item u-width-100 u-margin-top--2"
            to={getPath(languageRegion, undefined, currentCategory, currentSubCategory, filters)}
        >
            <ShopNow buttonClass="c-product-filter__link--primary-color u-width-100">Find Products</ShopNow>
        </Link>
    );
};

const Rating1ProductFilterComponent = (props) => {
    const {rating1ProductFilterValue, updateRating1ProductFilterValue} = useContext(ProductFiltersContext);

    // useEffect(() => {
    //     updateRating1ProductFilter();
    // }, []);

    const onChangeHandler = (newValue) => {
        updateRating1ProductFilterValue(newValue);
    };

    return (
        <RatingFilter
            className="g-flex__item"
            value={rating1ProductFilterValue}
            title={props.data.filterTitle}
            onChangeHandlerOverride={onChangeHandler}
        />
    );
};


const Rating2ProductFilterComponent = (props) => {
    const {rating2ProductFilterValue, updateRating2ProductFilterValue} = useContext(ProductFiltersContext);

    // useEffect(() => {
    //     updateRating2ProductFilter();
    // }, []);

    const onChangeHandler = (newValue) => {
        updateRating2ProductFilterValue(newValue);
    };

    return (
        <RatingFilter
            className="g-flex__item"
            value={rating2ProductFilterValue}
            title={props.data.filterTitle}
            onChangeHandlerOverride={onChangeHandler}
        />
    );
};

const Rating3ProductFilterComponent = (props) => {
    const {rating3ProductFilterValue, updateRating3ProductFilterValue} = useContext(ProductFiltersContext);

    // useEffect(() => {
    //     updateRating3ProductFilter(props.data);
    // }, []);

    const onChangeHandler = (newValue) => {
        updateRating3ProductFilterValue(newValue);
    };

    return (
        <RatingFilter
            className="g-flex__item"
            value={rating3ProductFilterValue}
            title={props.data.filterTitle}
            onChangeHandlerOverride={onChangeHandler}
        />
    );
};

// const OrderByProductFilterComponent = (props) => {
//     const {orderByProductFilter, updateOrderByProductFilter} = useContext(ProductFiltersContext);

//     useEffect(() => {
//         updateOrderByProductFilter(props.data);
//     }, []);

//     const onChangeHandler = (newValue) => {
//         updateOrderByProductFilter(newValue);
//     };

//     if (!orderByProductFilter ) return;

//     return (
//         <div key={"product-filter-categorys"} className="g-flex__item g-flex__item--full-width c-product-filter__filter-item">
//             {/* <OrderByFilter
//                 title={"Order by"}
//                 productFilter={props.data}
//                 onChangeHandlerOverride={onChangeHandler}
//             /> */}
//         </div>
//     );
// };

// const getDefaultPriceFilterValue = (value, defaultValue, highestPrice) => {
//     if (value && value[1] <= highestPrice) {
//         return value;
//     } else if (defaultValue && defaultValue[1] <= highestPrice) {
//         return defaultValue;
//     } else {
//         return [0, highestPrice];
//     }
// };

// const PriceFilterComponent = (props) => {
//     const { languageRegion } = useContext(LanguageRegionContext);
//     const {minMaxProductFilter, updateMinMaxProductFilter} = useContext(ProductFiltersContext);
//     const min = getMin(props.data.acceptedValues);
//     const max = getMax(props.data.acceptedValues);
//     const defaultValue = getDefaultPriceFilterValue(props.data.value, props.data.defaultValueForMinMax, props.highestPrice || max);
//     const valuesForMinMax = getValuesForMinMax(min, max);
//     const possibleValues = valuesForMinMax.reduce((acc, currentArray) => {
//         const highestPrice = props.highestPrice || max;
//         currentArray.forEach(value => {
//             if(!acc.includes(value) && value <= highestPrice) {
//                 acc.push(value);
//             }
//         });

//         return acc;
//     }, []).sort((a, b) => a - b);

//     useEffect(() => {
//         updateMinMaxProductFilter(props.data);
//     }, []);

//     const onChangeHandler = (newValue) => {
//         updateMinMaxProductFilter(newValue);
//     };

//     if (!minMaxProductFilter ) return;

//     return (
//         <MinMaxFilter
//             languageRegion={languageRegion}
//             title={"Price range"}
//             defaultValue={defaultValue}
//             possibleValues={possibleValues}
//             productFilter={props.data}
//             onChangeHandlerOverride={onChangeHandler}
//         />
//     );
// };

const CategoryFilterComponent = (props) => {
    const { updateCurrentCategory } = useContext(CategoryContext);
    const defaultValue = props.category && props.category.slug || props.categories && props.categories[0].slug;

    useEffect(() => {
        const staticPageCategory = props.category && props.category.slug || props.categories && props.categories[0].slug;
        updateCurrentCategory(staticPageCategory);
    }, [props.category]);

    const onChangeHandlerOverride = (newCategory) => {
        updateCurrentCategory(newCategory);
    };

    if (!props.categories ) return;

    return (
        <div key={"product-filter-categorys"} className="g-flex__item g-flex__item--full-width c-product-filter__filter-item">
            <CategoryFilter
                title={"Category"}
                defaultValue={defaultValue}
                categories={props.categories}
                onChangeHandlerOverride={onChangeHandlerOverride}
            />
        </div>
    );
};

const SubCategoryFilterComponent = (props) => {
    const { updateCurrentSubCategory } = useContext(SubCategoryContext);
    const defaultValue = props.subCategory && props.subCategory && props.subCategory.slug || null;

    useEffect(() => {
        const staticPageCategory = props.subCategory && props.subCategory && props.subCategory.slug || null;
        updateCurrentSubCategory(staticPageCategory);
    }, [props.subCategory]);


    const onChangeHandlerOverride = (newSubCategory) => {
        updateCurrentSubCategory(newSubCategory);
    };

    if (!props.subCategories ) return;

    return (
        <div key={"product-filter-sub-categorys"} className="g-flex__item g-flex__item--full-width c-product-filter__filter-item">
            <CategoryFilter
                title={"Sub Category"}
                defaultValue={defaultValue}
                categories={props.subCategories}
                onChangeHandlerOverride={onChangeHandlerOverride}
            />
        </div>

    );
};

const ProductFiltersContainerComponent = ({ mobile, children }) => {
    const { isDesktopProductFiltersOpen, isProductFiltersOpen, updateIsDesktopProductFiltersOpen, updateIsProductFiltersOpen } = useContext(MenuContext);

    const onToggleProductFilters = () => {
        updateIsProductFiltersOpen(!isProductFiltersOpen);
    };

    const onToggleDesktopProductFilters = () => {
        updateIsDesktopProductFiltersOpen(!isDesktopProductFiltersOpen);
    };

    const componentClassName = mobile ? "g-flex__container g-flex__container--column-always c-product-filter__container--mobile" : "g-flex__container g-flex__container--column-always c-product-filter__container--desktop";

    if (mobile) {
        const selectClassNames = isProductFiltersOpen ? "g-flex__item--grow g-flex__item--full-width c-product-filter__filter-wrapper" : "u-visually-hidden";
        const buttonText = isProductFiltersOpen ? "Close Advanced Product Search and Filters" : "Open Advanced Product Search and Filters";
        const buttonClassNames = isProductFiltersOpen ? "c-product-filter__button c-product-filter__button--active" : "c-product-filter__button c-product-filter__button--inactive";

        return (
            <div className="c-product-filter">
                <div id="scroll-target" className={componentClassName}>
                    <div className={selectClassNames}>
                        {children}
                    </div>
                    <button onClick={() => onToggleProductFilters()} className={`g-flex__item g-flex__item--full-width g-flex__container g-flex__container--column-always g-flex__container--align-center ${buttonClassNames}`}><span className="g-flex__item">{buttonText}</span></button>
                </div>
            </div>
        );
    } else {
        const selectClassNames = isDesktopProductFiltersOpen ? "g-flex__item--grow c-product-filter__filter-wrapper" : "u-visually-hidden";
        const buttonText = isDesktopProductFiltersOpen ? "Close Filters" : "Open Filters";
        const buttonClassNames = isDesktopProductFiltersOpen ? "c-product-filter__desktop-button c-product-filter__desktop-button--active" : "c-product-filter__desktop-button c-product-filter__desktop-button--inactive";

        return (
            <div className="c-product-filter">
                <div id="scroll-target" className={componentClassName}>
                    <button onClick={() => onToggleDesktopProductFilters()} className={`g-flex__item  g-flex__container  g-flex__container--align-center ${buttonClassNames}`}><span className="g-flex__item">{buttonText}</span></button>
                    <div className={selectClassNames}>
                        {children}
                    </div>
                </div>
            </div>
        );
    }
};

export const ProductFilter = (props) => {
    const data = useStaticQuery(graphql`
    query {
        productFilters: allNewProductFilter {
            edges {
                node {
                    filterId
                    acceptedValues
                    defaultValue
                    defaultValues
                    enabled
                    filterName
                    filterTitle
                    filterType
                    operator
                    queryStringParam
                    valuesForOrder {
                        direction
                        orderBy
                        stateValue
                        title
                    }
                    defaultValueForOrder {
                        direction
                        orderBy
                        stateValue
                        title
                    }
                }
            }
        }
        categories: allCategory {
            edges {
                node {
                    ...Categorys
                }
            }
        }
        subCategories: allSubCategory {
            edges {
                node {
                    ...SubCategorys
                }
            }
        }
    }
    `);
    const productFilters = getProductFilters(data.productFilters);
    const categories = getCategories(data.categories);
    const subCategories = getSubCategories(data.subCategories);

    const rating1ProductFilterData = productFilters[0];
    const rating2ProductFilterData = productFilters[1];
    const rating3ProductFilterData = productFilters[2];
    const minMaxProductFilterData = productFilters[3];
    const orderByProductFilterData = productFilters[4];

    return (
        <ProductFiltersContainerComponent
            mobile={props.mobile}
        >
            <div className="c-product-filter--desktop">
                <h2>Product Filters</h2>
                <div className="g-flex__container g-flex__container--column-always c-product-filter__rating-filters">
                    <ProductFiltersContextProvider>
                        <CategoryContextProvider>
                            <SubCategoryContextProvider>
                                {/* <OrderByProductFilterComponent
                                    data={{...orderByProductFilterData, enabled: true }}
                                /> */}
                                <CategoryFilterComponent
                                    category={props.category}
                                    categories={categories}
                                />
                                <SubCategoryFilterComponent
                                    subCategory={props.subCategory}
                                    subCategories={subCategories}
                                />
                                <Rating1ProductFilterComponent
                                    data={{...rating1ProductFilterData, enabled: true }}
                                />
                                <Rating2ProductFilterComponent
                                    data={{...rating2ProductFilterData, enabled: true }}
                                />
                                <Rating3ProductFilterComponent
                                    data={{...rating3ProductFilterData, enabled: true }}
                                />
                                {/* <PriceFilterComponent
                                    highestPrice={props.highestPrice}
                                    data={{...minMaxProductFilterData, enabled: true }}
                                /> */}
                                <FindProductsLink
                                    rating1QueryStringParam={rating1ProductFilterData.queryStringParam}
                                    rating2QueryStringParam={rating2ProductFilterData.queryStringParam}
                                    rating3QueryStringParam={rating3ProductFilterData.queryStringParam}
                                    minMaxQueryStringParam={minMaxProductFilterData.queryStringParam}
                                    orderByQueryStringParam={orderByProductFilterData.queryStringParam}
                                />
                            </SubCategoryContextProvider>
                        </CategoryContextProvider>
                    </ProductFiltersContextProvider>
                </div>
            </div>
        </ProductFiltersContainerComponent>
    );
};
