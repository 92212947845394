import React, { useEffect, useState } from "react";

import "./select.scss";

export const CategoryFilter = (props) => {
    const [value, setValue] = useState(props.defaultValue);

    useEffect(() => {
        const upcomingDefaultValue = props.defaultValue;
        setValue([upcomingDefaultValue]);

    }, [props.defaultValue]);

    const changeHandler = (event) => {
        const value = event.target[event.target.selectedIndex].getAttribute("value");

        setValue(value);
        if (props.onChangeHandlerOverride) {
            props.onChangeHandlerOverride(value);
        }
    };

    return (
        <div className="c-product-filters-category">
            <h3><label htmlFor={`select-category-${props.title}`}>{props.title}</label></h3>
            <select
                name={`select-category-${props.title}`}
                value={value || ""}
                onChange={changeHandler}
            >
                <option value={""}>All</option>
                {props.categories && props.categories.map((category, index) => {
                    return (
                        <option key={`select-category-label-${index}`} value={category.slug}>{category.formattedName}</option>
                    );
                })}
            </select>
        </div>
    );
};