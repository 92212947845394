import React, { useState, createContext } from "react";

const defaultContext = {
    currentSubCategory: "",
    updateCurrentSubCategory: () => { }
};

export const SubCategoryContext = createContext(defaultContext);

const Provider = (props) => {
    const [currentSubCategory, setCurrentSubCategory] = useState("");

    return (
        <SubCategoryContext.Provider
            value={{
                currentSubCategory,
                updateCurrentSubCategory: (newSubCategoryContext) => setCurrentSubCategory(newSubCategoryContext),
            }}
        >
            {props.children}
        </SubCategoryContext.Provider>
    );
};


export const SubCategoryContextProvider = (props) => {
    return (
        <Provider>{props.children}</Provider>
    );
};