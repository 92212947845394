import React, { useState, createContext } from "react";

import { useLocation } from "@reach/router";
import { graphql, useStaticQuery } from "gatsby";
import queryString from "query-string";

const getQueryParameterFilters = (query) => {
    const fallback = {
        rating1Filter: 0,
        rating2Filter: 0,
        rating3Filter: 0,
        ratingsTotalFilter: 0,
        currentPriceFilter: [0, 500],
        orderBy: "newestPriceChangeDESC"
    };

    let rating1FilterDefault;
    let rating2FilterDefault;
    let rating3FilterDefault;
    let ratingsTotalFilterDefault;
    let currentPriceFilterDefault;
    let orderByDefault;

    if (query) {
        const queriedTheme = queryString.parse(query);
        const { r1, r2, r3, rTotal, minmax, orderBy } = queriedTheme;

        // Ensure a valid expected value is passed
        if (["1", "2", "3", "4", "5"].includes(r1)) {
            rating1FilterDefault = r1;
        } else {
            rating1FilterDefault = fallback.rating1FilterDefault;
        }

        if (["1", "2", "3", "4", "5"].includes(r2)) {
            rating2FilterDefault = r2;
        } else {
            rating2FilterDefault = fallback.rating2FilterDefault;
        }

        if (["1", "2", "3", "4", "5"].includes(r3)) {
            rating3FilterDefault = r3;
        } else {
            rating3FilterDefault = fallback.rating3FilterDefault;
        }

        if (["1", "2", "3", "4", "5"].includes(rTotal)) {
            ratingsTotalFilterDefault = rTotal;
        } else {
            ratingsTotalFilterDefault = fallback.ratingsTotalFilterDefault;
        }

    }

    return {
        rating1FilterDefault,
        rating2FilterDefault,
        rating3FilterDefault,
        ratingsTotalFilterDefault,
        currentPriceFilterDefault,
        orderByDefault
    };
};


export const ProductFiltersContext = createContext({
    rating1ProductFilterValue: {},
    updateRating1ProductFilterValue: () => { },
    rating2ProductFilterValue: {},
    updateRating2ProductFilterValue: () => { },
    rating3ProductFilterValue: {},
    updateRating3ProductFilterValue: () => { },
});

const Provider = (props) => {
    const location = useLocation();
    const { rating1FilterDefault, rating2FilterDefault, rating3FilterDefault, ratingsTotalFilterDefault, currentPriceFilterDefault, orderByDefault } = (location.search && getQueryParameterFilters(location.search));

    const [rating1ProductFilterValue, setRating1ProductFilterValue] = useState(rating1FilterDefault);
    const [rating2ProductFilterValue, setRating2ProductFilterValue] = useState(rating2FilterDefault);
    const [rating3ProductFilterValue, setRating3ProductFilterValue] = useState(rating3FilterDefault);
    const [orderByProductFilterValue, setOrderByProductFilterValue] = useState(orderByDefault);
    const [minMaxProductFilterValue, setMinMaxProductFilterValue] = useState(currentPriceFilterDefault);

    return (
        <ProductFiltersContext.Provider
            value={{
                rating1ProductFilterValue,
                updateRating1ProductFilterValue: (newFilter) => setRating1ProductFilterValue(newFilter),
                rating2ProductFilterValue,
                updateRating2ProductFilterValue: (newFilter) => setRating2ProductFilterValue(newFilter),
                rating3ProductFilterValue,
                updateRating3ProductFilterValue: (newFilter) => setRating3ProductFilterValue(newFilter),
                orderByProductFilterValue,
                updateOrderByProductFilterValue: (newFilter) => setOrderByProductFilterValue(newFilter),
                minMaxProductFilterValue,
                updateMinMaxProductFilterValue: (newFilter) => setMinMaxProductFilterValue(newFilter),
            }}
        >
            {props.children}
        </ProductFiltersContext.Provider>
    );
};


export const ProductFiltersContextProvider = (props) => {
    return (
        <Provider>{props.children}</Provider>
    );
};