import React, { useContext } from "react";

import { Link } from "gatsby";

import { LanguageRegionContext } from "../../context-providers/language-region-context";
import { getPath } from "../../helpers/get-path";
import "./affiliate-disclosure.scss";

export const AffiliateDisclosure = () => {
    const { languageRegion } = useContext(LanguageRegionContext);

    return (
        <div className="c-affiliate-disclosure u-width-100">
            <p>The links on this page may be affiliate links. If you use these links to buy something we may earn a commission. Thanks! If you want to learn more please read our <Link to={getPath(languageRegion, "affiliate-disclosure")}>affiliate disclosure</Link>.</p>
        </div>
    );
};
