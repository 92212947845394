
const getProductFilterNodes = (productFilterData) => {
    let productFilters = [];
    if (productFilterData && productFilterData.edges) {
        productFilterData.edges.forEach(el => {
            if (el.node) productFilters.push(el.node);
        });
    }

    return productFilters;
};

const getProductFiltersDefaultValues = (productFilters) => {
    return {
        rating1DefaultValue: productFilters && productFilters[0] && productFilters[0].defaultValue,
        rating2DefaultValue: productFilters && productFilters[1] && productFilters[1].defaultValue,
        rating3DefaultValue: productFilters && productFilters[2] && productFilters[2].defaultValue,
        minMaxDefaultValue: productFilters && productFilters[3] && productFilters[3].defaultValues,
        orderByDefaultValue: productFilters && productFilters[4] && productFilters[4].defaultValueForOrder && productFilters[4].defaultValueForOrder.stateValue,
    };
};


const getProductFilters = (productFilterData, productFilterValues) => {
    const productFilterNodes = productFilterData && getProductFilterNodes(productFilterData);

    if (productFilterValues && productFilterValues.length > 0 && productFilterNodes && productFilterNodes.length > 0) {
        return productFilterNodes.map((productFilter) => {
            const matchingProductFilterValues = productFilterValues.find(filterValueObj => productFilter.filterId === filterValueObj.id);

            if (matchingProductFilterValues) {
                // Prices Filter
                if (productFilter.filterType === "PriceFilter" && matchingProductFilterValues.values[0] && matchingProductFilterValues.values[1]) {
                    return {
                        ...productFilter,
                        min: matchingProductFilterValues.values[0],
                        max: matchingProductFilterValues.values[1]
                    };
                }
                // Order Filter
                if (productFilter.filterType === "OrderBy") {
                    const newValue = productFilter.valuesForOrder.find(valueForOrder => valueForOrder.orderId === matchingProductFilterValues.value);

                    return {
                        ...productFilter,
                        value: newValue
                    };
                }
                // Rating Filter
                if (productFilter.filterType === "RatingFilter" && matchingProductFilterValues.value) {
                    return {
                        ...productFilter,
                        value: matchingProductFilterValues.value
                    };
                }

                console.error("getProductFilters is missing a filter operator");
            } else {
                return productFilter;
            }
        });
    }

    return productFilterNodes;
};

module.exports = {
    getProductFilters,
    getProductFiltersDefaultValues
};