import React, { useContext} from "react";

import { AffiliateDisclosure } from "../../components/affiliate-disclosure";
import { ProductFilter } from "../../containers/product-filter";
import { LanguageRegionContext } from "../../context-providers/language-region-context";
import { MenuContext } from "../../context-providers/menu-context";

import "./content-layout.scss";

export const ContentLayout = (props) => {
    const {
        isDesktopProductFiltersOpen,
    } = useContext(MenuContext);
    const { languageRegion } = useContext(LanguageRegionContext);

    const filterClass = isDesktopProductFiltersOpen ? "g-grid__container--content-layout--filters" : "g-grid__container--content-layout--filters-closed";
    const layoutClasses = props.filters ? `g-grid__container--content-layout ${filterClass}` : "g-grid__container--content-layout";
    const marginClasses = props.margin ? "g-grid__container--content-layout--margin" : "";

    return (
        <div className="c-content-layout">
            <div className="g-grid__container--content-layout-title">
                <div className="g-grid__item--title-pl"></div>
                <div className="g-grid__item--title-main">
                    <div className="g-grid__container--content-layout-header c-content-layout__container">
                        <div className="g-grid__item--content-layout-title">
                            {
                                props.title && <h1 className="c-content-layout__title">
                                    {props.title}
                                </h1>
                            }
                        </div>
                        <div className="g-grid__item--content-layout-space"></div>
                        <div className="g-grid__item--content-layout-disclosure c-content-layout__affiliate-disclosure-container">
                            {props.affiliateDisclosure && <AffiliateDisclosure />}
                        </div>
                    </div>
                </div>
                <div className="g-grid__item--title-pr"></div>
            </div>
            <div className={`${layoutClasses} ${marginClasses}`}>
                {props.filtersEnabled &&
                    <>
                        <div className="g-grid__item--content-pl">
                            <ProductFilter
                                languageRegion={languageRegion}
                                key={"product-filter--mobile-false"}
                                mobile={false}
                                category={props.category}
                                subCategory={props.subCategory}
                                highestPrice={props.highestPrice}
                            />
                        </div>
                        <div className="g-grid__item--content-main">
                            <ProductFilter
                                languageRegion={languageRegion}
                                key={"product-filter--mobile-true"}
                                mobile={true}
                                category={props.category}
                                subCategory={props.subCategory}
                                highestPrice={props.highestPrice}
                            />
                            {props.children}
                        </div>
                        <div className="g-grid__item--content-pr"></div>
                    </>
                }
                {!props.filtersEnabled &&
                    <>
                        <div className="g-grid__item--content-pl"></div>
                        <div className="g-grid__item--content-main">
                            {props.children}
                        </div>
                        <div className="g-grid__item--content-pr"></div>
                    </>
                }
            </div>
        </div>
    );
};

export default ContentLayout;
