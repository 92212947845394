import React, { useState, useEffect } from "react";

import { useRanger } from "react-ranger";

import "./rating-filter.scss";

const getTicks = (ticks) => ticks.map(({ value, getTickProps }, index) => (
    <div
        className="c-rating-filter__tick"
        key={`rating-filter-tick-${index}`}
        {...getTickProps()}>
        <div className="c-rating-filter__tick-label">{value}</div>
    </div>
));

const getHandles = (handles) => handles.map(({ value, getHandleProps, active }, index) => (
    <div
        className={`g-flex__item c-rating-filter__handle ${active ? "active" : ""}`}
        active={value && value.toString()}
        key={`rating-filter-handle-${index}`}
        {...getHandleProps()}
    >
        {value}
    </div>
));

const getSegments = (segments) => segments.map(({ getSegmentProps, value }, index) => (
    <div className={`c-rating-filter__segment c-rating-filter__segment--${value}`} key={`rating-filter-segment-${index}`} {...getSegmentProps()} index={index} ></div>
));

export const RatingFilter = (props) => {
    const [values, setValues] = useState([(props.value || 0)]);

    useEffect(() => {
        if (props.value) {
            setValues([props.value]);
        }
    }, [props.value]);

    const changeHandler = (values) => {
        setValues(values);
        if (props.onChangeHandlerOverride) {
            const newProductFilters = parseInt(values[0]);

            props.onChangeHandlerOverride(newProductFilters);
        }
    };

    const { getTrackProps, ticks, segments, handles } = useRanger({
        min: 0,
        max: 5,
        stepSize: 1,
        values,
        ticks: [0,1,2,3,4,5],
        onChange: changeHandler
    });

    return (
        <div className="g-flex__container g-flex__container--column-always g-flex__item g-flex__item--full-width c-product-filter__filter-item">
            <div className={"c-rating-filter g-flex__item"}>
                <h3>{props.title}</h3>
                <div className="g-flex__container c-rating-filter__range-slider">
                    <div
                        className="g-flex__item c-rating-filter__range"
                        key={"rating-filter-track"}
                        {...getTrackProps({})}
                    >
                        {getTicks(ticks)}
                        {getSegments(segments)}
                        {getHandles(handles)}
                    </div>
                    <span className={`c-rating-filter__value c-rating-filter__value--${values[0]}`}>{values} +</span>
                </div>
            </div>
        </div>
    );
};

